<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
     <!-- BOC:[loader] -->
    <ALoader :isLoading="api.isLoading"></ALoader>
    <!-- EOC -->
    <div class="d-flex" v-if="studentIds">
      <v-btn
        :disabled="studentIndex == 0"
        @click="previous"
        color="blue-grey"
        class="ma-2 white--text"
      >
        <v-icon left dark> mdi-arrow-left </v-icon>
        {{ $t("action.previous") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="studentIndex == studentIds.length - 1"
        @click="next"
        color="blue-grey"
        class="ma-2 white--text"
      >
        {{ $t("action.next") }}
        <v-icon right dark> mdi-arrow-right </v-icon>
      </v-btn>
    </div>
    <div v-if="isSchool || auth.token">
      <v-card
        v-if="!api.isError"
        :loading="api.isLoading"
        elevation="2"
        class="mx-auto"
        outlined
      >
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t("model.prop.fullname") }}</th>
                <th class="text-left">{{ $t("model.name.classroom") }}</th>
                <th class="text-left">{{ $t("model.prop.status") }}</th>
                <th class="text-left">{{ $t("model.prop.totalAttempt") }}</th>
                <th class="text-left">{{ $t("model.prop.totalSubmission") }}</th>
                <th class="text-left">{{ $t("model.prop.progress") }}</th>
                <th class="text-left">{{ $t("model.prop.totalStar") }}</th>
                <th class="text-left">{{ $t("model.prop.totalScore") }}</th>
                <th class="text-left">{{ $t("model.prop.weakness") }}</th>
              </tr>
            </thead>
            <tbody v-if="!api.isLoading">
              <tr v-if="data.Record">
                <td>{{ data.name }}</td>
                <td>{{ data.Student.Classroom.name }}</td>
                <td><ChipStudentStatus :data="data"></ChipStudentStatus></td>
                <td>{{ Attempt.length }}</td>
                <td>{{ data.totalAttempt }}</td>
                <td>{{ data.progress }}/{{ data.Event.Checkpoint.length }}</td>
                <td>{{ data.totalStar }} ★</td>
                <td>{{ data.totalScore }}</td>
                <td v-if="data.Record">
                  <span v-if="weaknessQuestion[0]">
                    <router-link
                      class="text-decoration-none"
                      :to="{
                        name: 'PageMainDiscoverQuiz',
                        params: {
                          code: weaknessQuestion[0].Checkpoint.Question.key,
                        },
                      }"
                    >
                      {{
                        JSON.parse(
                          weaknessQuestion[0].Checkpoint.Question
                            .translatableName
                        )[$_getLocale()]
                      }}</router-link
                    >
                  </span>
                  <span v-else>Perfect score</span>
                </td>
                <td v-else>-</td>
              </tr>
              <tr v-else>
                <td v-if="data.Student">{{ data.Student.name }}</td>
                <td v-if="data.Student">{{ data.Student.Classroom.name }}</td>
                <td><ChipStudentStatus :data="data"></ChipStudentStatus></td>
                <td>-</td>
                <td v-if="data.Checkpoint">0/{{ data.Checkpoint.length }}</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
            <tbody v-if="api.isLoading">
              <td><ASkeletonLoader type="text" width="100%" /></td>
              <td><ASkeletonLoader type="text" width="100%" /></td>
              <td><ASkeletonLoader type="text" width="100%" /></td>
              <td><ASkeletonLoader type="text" width="100%" /></td>
              <td><ASkeletonLoader type="text" width="100%" /></td>
              <td><ASkeletonLoader type="text" width="100%" /></td>
              <td><ASkeletonLoader type="text" width="100%" /></td>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-card
        height="350px"
        v-if="submissionLog && submissionLog.length != 0"
        elevation="2"
        class="mx-auto mt-3"
        outlined
      >
        <v-card-text v-if="!api.isLoading">
          <v-row v-if="!isLoadingSubmission">
            <v-col cols="12" md="6">
              <ChartAttempt
                :start="data.Event.timestampStart"
                :end="data.Event.timestampEnd"
                :submission="submissionLog"
              />
            </v-col>
            <v-col cols="12" md="6">
              <ChartGrowth
                :start="data.Event.timestampStart"
                :end="data.Event.timestampEnd"
                :submission="submissionLog"
                :totalAttempt="data.totalAttempt"
                :totalScore="data.totalScore"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12" md="6">
              <ASkeletonLoader type="card" width="100%" />
            </v-col>
            <v-col cols="12" md="6">
              <ASkeletonLoader type="card" width="100%" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="!api.isError" elevation="2" class="mx-auto mt-3" outlined>
        <v-card-text v-if="api.isLoading">
          <ASkeletonLoader type="table" width="100%" />
        </v-card-text>
        <v-card-text v-else>
          <v-simple-table fixed-header height="400px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("model.prop.no") }}</th>
                  <th class="text-left">{{ $t("model.name.chapter") }}</th>
                  <th class="text-left">{{ $t("model.name.question") }}</th>
                  <th class="text-left">{{ $t("model.prop.attempt") }}</th>
                  <th class="text-left">{{ $t("model.name.submissions") }}</th>
                  <th class="text-left">{{ $t("model.prop.stars") }}</th>
                  <th class="text-left">{{ $t("model.prop.score") }}</th>
                  <th class="text-left">{{ $t("model.prop.corrects") }}</th>
                  <th class="text-left">{{ $t("model.prop.accuracy") }}</th>
                  <th class="text-left">{{ $t("model.prop.time") }}</th>
                </tr>
              </thead>
              <tbody
                v-for="(item, index) in data.Record ? allCheckpoint : null"
                :key="index"
              >
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td>
                    ({{ item.Record.Checkpoint.Chapter.sort }})
                    {{
                      JSON.parse(
                        item.Record.Checkpoint.Chapter.translatableName
                      )[$_getLocale()]
                    }}
                  </td>
                  <td>
                    {{
                      JSON.parse(
                        item.Record.Checkpoint.Question.translatableName
                      )[$_getLocale()]
                    }}
                  </td>
                  <td v-if="item.Attempt">{{item.Attempt.length}}</td>
                  <td v-else>0</td>
                  <td
                    @click="
                      showSubmission(
                        item.Record.Checkpoint.chapterId,
                        item.Record.Checkpoint.questionKey
                      )
                    "
                  >
                    {{ item.Record.attempt }}
                    <span v-if="item.Submission && item.Submission.length > 0"
                      ><v-icon
                        v-if="
                          questionKeyShow ==
                            item.Record.Checkpoint.questionKey &&
                          chapterIdShow == item.Record.Checkpoint.chapterId
                        "
                        >mdi-menu-up</v-icon
                      >
                      <v-icon v-else>mdi-menu-down</v-icon>
                    </span>
                  </td>
                  <td v-if="item.Record.topStar">
                    {{ item.Record.topStar }} ★
                  </td>
                  <td v-else-if="item.Submission && !item.Record.topStar">
                    0 ★
                  </td>
                  <td v-else>-</td>
                  <td v-if="item.Record.topScore">
                    {{ item.Record.topScore }}
                  </td>
                  <td v-else>-</td>
                  <td v-if="item.Submission && item.Submission.length > 0">
                    {{ item.Best.countCorrect }}/{{ item.Best.countQuestion }}
                  </td>
                  <td v-else>-</td>
                  <td v-if="item.Submission && item.Submission.length > 0">
                    {{ item.Best.accuracy * 100 }}%
                  </td>
                  <td v-else>-</td>
                  <td v-if="item.Submission && item.Submission.length > 0">
                    {{ item.Best.time }}
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr
                  v-for="(item, index) in item.Submission &&
                  questionKeyShow == item.Record.Checkpoint.questionKey &&
                  chapterIdShow == item.Record.Checkpoint.chapterId
                    ? item.Submission
                    : null"
                  :key="index"
                  class="submission"
                >
                  <td></td>
                  <td></td>
                  <td>
                    {{
                      $moment(item.timestampCreated).format(
                        "DD/MM/YYYY hh:mm a"
                      )
                    }}
                  </td>
                  <td>{{ item.attempt }}</td>
                  <td>{{ item.star }} ★</td>
                  <td>{{ item.score }}</td>
                  <td>{{ item.countCorrect }}/{{ item.countQuestion }}</td>
                  <td>{{ item.accuracy * 100 }}%</td>
                  <td>{{ item.time }}</td>
                </tr>
                <tr></tr>
              </tbody>
              <tbody
                v-for="(item, index) in data.Record ? null : data.Checkpoint"
                :key="index"
              >
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ item.Chapter.sort }}.
                    {{
                      JSON.parse(item.Chapter.translatableName)[$_getLocale()]
                    }}
                  </td>
                  <td>
                    {{
                      JSON.parse(item.Question.translatableName)[$_getLocale()]
                    }}
                  </td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr></tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
      <div
        v-if="data.totalAttempt - submissionLog.length > 1 && data.Record"
        class="text-caption text-right pt-2"
      >
        {{ data.totalAttempt - submissionLog.length
        }}{{ $t("view.PageMainEventScoreboardStudent.missing_attempt") }}
      </div>
    </div>
    <div v-else>
      <div v-if="!$route.query.s && !api.isLoading">
        <AlertProtection />
      </div>
      <div v-else-if="$route.query.s && !api.isLoading">
        <AlertProtectionFailed />
      </div>
    </div>
  </v-container>
</template>

<script>
import ChipStudentStatus from "@/components/Scoreboard/ChipStudentStatus";
import AlertProtection from "@/components/Festival/SchoolCode/AlertProtection";
import AlertProtectionFailed from "@/components/Festival/SchoolCode/AlertProtectionFailed";
import ChartAttempt from "@/components/Scoreboard/Student/ChartAttempt";
import ChartGrowth from "@/components/Scoreboard/Student/ChartGrowthV2";
import { mapState } from "vuex";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
    studentIds: (state) => state.student.data.studentIds,
  }),
  components: {
    ChipStudentStatus,
    AlertProtection,
    AlertProtectionFailed,
    ChartAttempt,
    ChartGrowth,
  },
  data: () => ({
    isSchool: false,
    isLoadingSubmission: false,
    questionKeyShow: null,
    chapterIdShow: null,
    studentName: null,
    eventCode: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    attemptApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    filterData: [],
    Submission: [],
    allCheckpoint: [],
    submissionLog: [],
    weaknessQuestion: [],
    Record: [],
    Attempt:[],
    studentIndex: null,
    studentId: null,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.method = "get";
    this.attemptApi.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.attemptApi.callbackReset = () => {
      this.attemptApi.isLoading = true;
      this.attemptApi.isError = false;
    };
    this.attemptApi.callbackError = (e) => {
      this.attemptApi.isLoading = false;
      this.attemptApi.isError = true;
      this.attemptApi.error = e;
    };
    this.attemptApi.callbackSuccess = (resp) => {
      this.attemptApi.isLoading = false;
      this.Attempt = resp
      this.filter();
    }
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      if (resp.Student) {
        this.data = resp;
        if (this.$route.query.s == this.data.Student.Classroom.School.code) {
          this.isSchool = true;
        }
        this.getProgress();
        if (resp.Record) {
          this.Record = this.$_.cloneDeep(resp.Record);
          this.weaknessQuestion = this.Record.filter((e) => e.topStar < 3);
          this.weaknessQuestion.sort((a, b) =>
            a.Checkpoint.Question.key > b.Checkpoint.Question.key ? 1 : -1
          );
          this.fitchSubmission(this.data.id);
          if (this.$route.query.s) {
            this.breadcrumbs.push({
              text: resp.name,
              to: {
                name: "PageMainFestivalScoreboardStudent",
                params: {
                  studentId: this.$route.params.studentId,
                  eventCode: this.$route.params.eventCode,
                },
                query: { s: this.$route.query.s },
              },
              exact: false,
            });
          } else {
            this.breadcrumbs.push({
              text: resp.name,
              to: {
                name: "PageMainFestivalScoreboardStudent",
                params: {
                  studentId: this.$route.params.studentId,
                  eventCode: this.$route.params.eventCode,
                },
              },
              exact: false,
            });
          }

          //EOC
        } else {
          this.data.Checkpoint.sort((a, b) =>
            a.Question.key > b.Question.key ? 1 : -1
          );
          //
          this.breadcrumbs.push({
            text: resp.Student.name,
            to: {
              name: "PageMainFestivalScoreboardStudent",
              params: {
                studentId: this.$route.params.studentId,
                eventCode: this.$route.params.eventCode,
              },
              query: { s: this.$route.query.s },
            },
            exact: true,
          });
          //EOC
        }
      } else {
        this.isLoadingSubmission = false;
        this.submissionLog = resp;
        this.submissionLog.sort((a, b) => (a.star > b.star ? 1 : -1));
        this.fitchAttempt(this.data.id);
      }
      //
    };
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("route.PageMainHome"),
      to: { name: "PageMainHome" },
      exact: true,
    });
    this.breadcrumbs.push({
      text: this.$t("route.PageMainFestival"),
      to: { name: "PageMainFestival" },
      exact: true,
    });
    //EOC
    this.breadcrumbs.push({
      text: this.$route.params.festivalCode,
      to: {
        name: "PageMainFestivalRead",
        params: { festivalCode: this.$route.params.festivalCode },
        query: { s: this.$route.query.s, e: this.$route.params.eventCode },
      },
      exact: true,
    });
    //EOC
  },

  methods: {
    previous() {
      this.studentIndex = this.studentIndex - 1;
      this.$router.push({
        name: "PageMainEventScoreboardStudent",
        params: {
          eventCode: this.eventCode,
          studentId: this.studentIds[this.studentIndex],
        },
        query: { s: this.$route.query.s },
      });
    },
    next() {
      this.studentIndex = this.studentIndex + 1;
      this.$router.push({
        name: "PageMainEventScoreboardStudent",
        params: {
          eventCode: this.eventCode,
          studentId: this.studentIds[this.studentIndex],
        },
        query: { s: this.$route.query.s },
      });
    },
    getProgress() {
      var p = 0;
      if (this.data.Record) {
        for (var r of this.data.Record) {
          if (r.topStar > 0) {
            p = p + 1;
          }
        }
        this.data.progress = p;
      }
    },
    showSubmission(chapterId, questionKey) {
      if (
        this.chapterIdShow == chapterId &&
        this.questionKeyShow == questionKey
      ) {
        this.chapterIdShow = null;
        this.questionKeyShow = null;
      } else {
        this.questionKeyShow = questionKey;
        this.chapterIdShow = chapterId;
      }
    },
    fetch() {
      this.api.url =
        this.$api.servers.classroom +
        "/v1/" +
        this.$_getLocale() +
        "/event/" +
        this.$route.params.eventCode +
        "/student/" +
        this.studentId;
      this.$api.fetch(this.api);
    },
    fitchSubmission(id) {
      this.isLoadingSubmission = true;
      this.api.url =
        this.$api.servers.log +
        "/log/v1/" +
        this.$_getLocale() +
        "/submission/participant/" +
        id;
      this.$api.fetch(this.api);
    },
    fitchAttempt(id) {
      this.attemptApi.url =
        this.$api.servers.log +
        "/log/v1/" +
        this.$_getLocale() +
        "/attempt/participant/" +
        id;
      this.$api.fetch(this.attemptApi);
    },
    filter() {
      var data = this.data
      for (var n = 0, m = data.Event.Checkpoint.length; n < m; n++) {
        var attempt = this.Attempt.filter(function (entry) {
          return entry.questionKey ==  data.Event.Checkpoint[n].questionKey;
        });
        this.allCheckpoint.push({
          Record: {
            topStar: false,
            topScore: false,
            attempt: 0,
            Checkpoint: data.Event.Checkpoint[n],
          },
          Attempt: attempt,
          Submission: false,
        });
      }
      for (var i = 0, l = data.Record.length; i < l; i++) {
        for (var j = 0, k = this.submissionLog.length; j < k; j++) {
          if (
            data.Record[i].Checkpoint.questionKey ==
            this.submissionLog[j].questionKey
          ) {
            this.submissionLog[j].Question =
              data.Record[i].Checkpoint.Question;
            this.Submission.push(this.submissionLog[j]);
          }
        }
        attempt = this.Attempt.filter(function (entry) {
          return entry.questionKey ==  data.Record[i].Checkpoint.questionKey;
        });
        this.Submission.sort((a, b) =>
          a.timestampCreated < b.timestampCreated ? 1 : -1
        );
        this.filterData.push({
          Record: data.Record[i],
          Submission: this.Submission,
          Attempt: attempt,
          Best: this.$_.maxBy(this.Submission, "score"),
        });
        this.Submission = [];
      }
      this.filterCheckpoint();
    },
    filterCheckpoint() {
      if (this.allCheckpoint.length != this.filterData.length) {
        for (var i = 0, j = this.filterData.length; i < j; i++) {
          for (var n = 0, m = this.allCheckpoint.length; n < m; n++) {
            if (
              this.allCheckpoint[n].Record.Checkpoint.id ==
              this.filterData[i].Record.Checkpoint.id
            ) {
              this.allCheckpoint.splice(n, 1);
              this.allCheckpoint.push(this.filterData[i]);
            }
          }
        }
      } else {
        this.allCheckpoint = this.filterData;
      }

      this.allCheckpoint.sort((a, b) =>
        a.Record.Checkpoint.Question.key > b.Record.Checkpoint.Question.key
          ? 1
          : -1
      );
    },
  },
  mounted() {
    this.studentId = parseInt(this.$route.params.studentId);
    if (this.studentIds) {
      this.studentIndex = this.studentIds.indexOf(this.studentId);
    }
    if (this.studentIndex == -1) {
      this.$store.dispatch("removeStudents");
    }
    this.eventCode = this.$route.query.e;
    this.fetch();
  },
};
</script>

<style>
.submission {
  background-color: #e3f2fd;
}
.submission:hover {
  background-color: #bbdefb !important;
}
</style>